@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;


.text-fade-in {
  animation: fadeIn ease 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      display: none;
  }
  1% {
      opacity: 0;
      display: block;
  }
  100% {
      opacity: 1;
      display: block;
  }
}

.delay-1 {
  animation-delay: 0.3s;
}

.delay-2 {
  animation-delay: 0.6s;
}

.delay-3 {
  animation-delay: 1s;
}